import { LocaleResource } from './en';

const es = {
  main: {
    navbar: {
      home: 'Inicio',
      customers: 'Clientes',
      about: 'Acerca de',
      developers: 'Desarrolladores',
      language: 'Idioma',
    },
    hero: {
      topBadge: 'Soluciones globales de pagos',
      header: 'Envía dinero a todo el mundo',
      subHeading:
        'Desde dólares y euros hasta pesos y más, Sphere lleva las conversiones de divisas al futuro.',
      ctas: {
        primary: 'Para empresas',
        secondary: 'Para individuos',
      },
    },
    solutions: {
      header: {
        topBadge: 'Procesamiento de pagos optimizado',
        header: 'Pagos internacionales para empresas',
        subHeading:
          'Mejora el movimiento de dinero—desde transferencias bancarias y tarjetas hasta el soporte para todas las redes principales—\ntransferir dinero a través de internet nunca ha sido tan fácil.',
        ctas: {
          primary: 'Empieza ahora',
          secondary: 'Descubre más',
        },
        cards: {
          '0': {
            title: 'Para remesas internacionales',
            description:
              'Envía tu moneda preferida, y tus proveedores reciben USD, MXN, BRL, EUR a tasas de cambio inigualables.',
            bullets: {
              '0': 'Cumplimiento y seguridad',
              '1': 'Liquidación en tiempo real',
              '2': 'Cobertura global',
            },
          },
          '1': {
            title: 'Para individuos de alto patrimonio',
            description:
              'Servicios OTC, conversiones de divisas sin esfuerzo y soluciones completas para la gestión y seguimiento financiero.',
            bullets: {
              '0': 'Soporte dedicado 24/7',
              '1': 'Alta liquidez',
              '2': 'Privacidad y encriptación',
            },
          },
          '2': {
            title: 'Para Finanzas y FinTechs',
            description:
              'Acepta pagos como prefieras, aprovecha las conversiones automáticas en tu cuenta, y ofrece servicios de conversión personalizados para tus clientes.',
            bullets: {
              '0': 'Acuerdos de nivel de servicio',
              '1': 'Protección al cliente',
              '2': 'API y Soluciones que no se requiere código',
            },
          },
        },
      },
    },
    ramp: {
      topBadge: 'Soluciones de conversión',
      header: 'Conectando monedas globales',
      subHeading:
        'Sphere se encarga de las complejidades de ingeniería, operativas y regulatorias por ti—para que no tengas que hacerlo.',
      solutions: {
        '0': {
          title: 'Personalizado con tu marca',
          description:
            'Sphere maneja las complejidades de ingeniería, operativas y regulatorias—para que no tengas que hacerlo.',
          bullets: {
            '0': 'Disponible vía API, no-code, y widget embebible.',
            '1': 'Ahorra innumerables horas en el proceso de desarrollo.',
            '2': 'Precios inigualables',
          },
        },
        '1': {
          title: 'Conversión de monedas, fácil y rápido.',
          description:
            'Genera una billetera vinculada a tu cuenta bancaria. Envía monedas y recibe USD con un solo clic. Disponible para comerciantes seleccionados a través del panel de control de Sphere.',
        },
        '2': {
          title: 'Cumplimiento unificado',
          description:
            'Confía en nuestra experiencia global y licencias regulatorias para brindar la mejor experiencia de pagos a tus usuarios. Deja que nos encarguemos del monitoreo de transacciones, la verificación de sanciones y la detección de fraudes por ti.',
        },
      },
    },
    dashboard: {
      topBadge: 'Características',
      header: 'Panel de Sphere',
      subHeading:
        'Los enlaces de pago de Sphere son versátiles, personalizables y robustos. Se adaptan a tus necesidades, ya sea para compras únicas o suscripciones recurrentes.',
      solutions: {
        '0': {
          title: 'Enlaces de pago',
          description:
            'Acepta pagos directamente desde email, SMS, redes sociales, sitio web o aplicación. Disfruta de todo el poder de Sphere sin tener que escribir una sola línea de código.',
        },
        '1': {
          title: 'Suscripciones',
          description:
            'Ofrece suscripciones tradicionales o auténticas on-chain—directamente desde tarjeta, cuenta bancaria o billetera digital. Facturación flexible desde por minuto hasta anual, medida por asiento, y de volumen a niveles graduados. Recibe pagos directamente, sin intermediarios, unwrapping de tokens o relays de gas innecesarios.',
        },
        '2': {
          title: 'Herramientas de desarrollo',
          description:
            'Sphere simplifica tu proceso de ingeniería con funciones de pago integradas. Nos encargamos de las complejidades económicas globales, permitiendo que tus equipos se enfoquen en un desarrollo sin interrupciones en una sola plataforma.',
        },
        '3': {
          title: 'Análisis e insights de clientes',
          description:
            'Monitorea más de 15 KPIs, como MRR, churn, tasas de conversión y clics. Maximiza tus ingresos, aumenta las tasas de aprobación, recibe pagos más rápido y reduce contracargos aceptando una mayor variedad de pagos.',
        },
      },
    },
    developerTools: {
      topBadge: 'SDK',
      header: 'Kit de herramientas para desarrolladores',
      subHeading:
        'Aplicamos las mejores prácticas en pagos para ofrecer SDKs semánticos y fáciles de usar. Enfócate en hacer crecer tu negocio mientras nosotros nos encargamos de las auditorías de contratos inteligentes, la orquestación cross-chain y todo el mantenimiento técnico, operativo y de cumplimiento.',
      bullets: {
        '0': 'API RESTful simple, poderosa e intuitiva',
        '1': 'Código para copiar y pegar que simplemente funciona',
        '2': 'Documentación concisa y completa',
      },
      ctas: {
        primary: 'Documentación',
        secondary: 'Soporte al desarrollador',
      },
    },
    features: {
      header: {
        topBadge: 'Características',
        title: 'Una suite completa de pagos',
        description:
          'Conéctate de manera fluida a través de una única API o un panel fácil de usar en minutos.\nObtén acceso a cumplimiento de primer nivel, contabilidad, pagos, on/off-ramps, análisis y herramientas para desarrolladores.',
      },
      grid: {
        '0': 'Análisis completos',
        '1': 'Pagos y cobros multipartes',
        '2': 'Construcción colaborativa con ACLs basados en roles',
        '3': 'Detección de AML y fraude',
        '4': 'Seguro y certificado',
        '5': 'Notificaciones por email',
      },
      securityMarquees: {
        '0': 'OFAC\nsanciones',
        '1': 'Prácticas de mejor seguridad de FTC',
        '2': 'Prácticas de mejor seguridad de GDPR',
        '3': 'PCI\ncompliante',
      },

      benefits: {
        lowestFees: {
          title: 'Las tarifas más bajas del mercado',
          description:
            'Todas las transacciones con tarifas igualadas a las de la competencia, con las tarifas de red trasladadas al costo.',
        },
        convenientSwaps: {
          title: 'Intercambios convenientes',
          description:
            'Pagos directamente a tu cuenta bancaria o a la de un proveedor externo, de forma manual o programada, con tarifas competitivas a nivel OTC.',
        },
        instantSettlements: {
          title: 'Liquidaciones instantáneas',
          description:
            'Reciba su dinero en segundos o minutos, en lugar de días o semanas.',
        },
        splitPayouts: {
          title: 'Pagos divididos',
          description:
            'Divida los pagos automáticamente sin tarifas adicionales.',
        },
        nonCustodial: {
          title: 'No custodial',
          description:
            'Los pagos son de persona a persona y nunca manejamos fondos de clientes o comerciantes.',
        },
        audited: {
          title: 'Auditado',
          description:
            'Nuestros contratos son auditados por los mejores expertos de la industria. Regularmente realizamos modelado de amenazas, pruebas de penetración y evaluaciones de vulnerabilidades en nuestra infraestructura.',
        },
        compliant: {
          title: 'Compliance',
          description:
            'Sphere sigue todas las mejores prácticas según las regulaciones de EE.UU. y utiliza transmisores de dinero con licencia.',
        },
        encrypted: {
          title: 'Cifrado',
          description:
            'Todos los datos están cifrados y/o tokenizados en reposo y en tránsito. Nunca almacenamos PII sensible y tenemos ACLs estrictos.',
        },
      },
    },
    ctaSection: {
      header: '¿Listo para dar el siguiente paso?',
      description:
        'Forma parte de los líderes de la industria que ya están creciendo con Sphere.',
      button: 'Comenzar',
    },
    faq: {
      header: 'FAQ',
      description:
        'Preguntas frecuentes. ¿No encuentras la respuesta que buscas? Ponte en contacto con nosotros.',
      questions: {
        '0': {
          q: '¿Cuánto cuesta Sphere?',
          a: 'El acceso al software es gratuito, pero cobramos una pequeña tarifa por transacción. Precios personalizados para empresas están disponibles a pedido.',
        },
        '1': {
          q: '¿Qué redes son compatibles?',
          a: 'Compatibilizamos con todas las principales redes de tarjetas (Visa, Mastercard, American Express, Discover) en más de 120 países, transferencias bancarias y transferencias nacionales e internacionales.',
        },
        '2': {
          q: '¿Cómo se maneja la conversión de divisas?',
          a: 'Los clientes consienten en cambiar a una moneda diferente durante la transferencia, que se enruta a través de un intercambio o está sujeta a tasas de cambio al costo.',
        },
        '3': {
          q: '¿Sphere es auditado?',
          a: 'Sí, somos auditados por OtterSec y cumplimos con PCI-DSS. Nos ofrecemos voluntariamente para cumplir con los requisitos de MSB/VASP y empleamos un programa sólido de KYC/AML. Estamos en proceso de recibir nuestras certificaciones SOC2 e ISO/IEC 27001.',
        },
        '4': {
          q: '¿Sphere custodia fondos?',
          a: 'Nunca. Somos un proveedor de servicios tecnológicos, no un MSB/VASP ni un transmisor de dinero. Todos los pagos que implican custodia son manejados por transmisores de dinero con licencia, custodios calificados y bancos con licencia.',
        },
        '5': {
          q: '¿Es posible integrar Sphere con mi solución de pago existente?',
          a: 'Sí. Puedes usar tu propio Stripe u otros proveedores de pago, y usar Sphere para agregar soporte para pagos alternativos y automatización de back-office a través de nuestra API/SDK. Diseñamos nuestra API para usar flujos de datos y modelos de objetos similares para reducir la cantidad de infraestructura que se debe reescribir.',
        },
      },
    },
    footer: {
      slogan: 'Acelerando las monedas digitales para un mundo más conectado.',
      termsOfService: 'Términos de Servicio',
      privacyPolicy: 'Política de Privacidad',
      reportVulnerability: 'Reportar una vulnerabilidad',
    },
  },
  accessRequest: {
    steps: {
      '1': {
        title: 'Solicitar Acceso',
        descriptionParagraph1:
          'Para <0>individuos</0> que desean utilizar nuestra funcionalidad de rampa, ahora pueden hacerlo sin necesidad de contactarnos. <0>Simplemente visita</0> <1>spherepay.co/ramp</1> para registrarte y comenzar.',
        descriptionParagraph2:
          'Para empresas u otras solicitudes, por favor completa la información a continuación y un experto de Sphere se pondrá en contacto contigo pronto.',
        form: {
          walletAddress: 'Dirección de la Billetera',
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo Electrónico',
          businessName: 'Nombre de la Empresa',
          businessUrl: 'URL del Sitio Web de la Empresa',
          phoneNumber: 'Número de Teléfono',
        },
        notYourEmail: '¿No es tu correo?',
        errors: {
          emailAlreadyInUse:
            'Este correo electrónico ya está en uso para una solicitud activa. Por favor, intenta con otro.',
          emailAlreadyLinkedToAccount:
            'Este correo electrónico ya está vinculado a una cuenta. ¿Prefieres iniciar sesión?',
        },
      },
      '2': {
        title: 'Información de Contacto',
        form: {
          preferredContactMethod: 'Método de Contacto Preferido',
          preferredContactMethodDescription:
            'Los detalles de acceso a la cuenta se enviarán por correo electrónico.',
          placeholders: {
            telegram: '@nombredeusuario',
            whatsapp: '+1234567890',
            email: 'tu@ejemplo.com',
            sms: '+1234567890',
          },
        },
      },
      '3': {
        title: 'Detalles del Negocio',
        form: {
          bankAccountJurisdiction:
            '¿Su negocio tiene una cuenta bancaria en algún de los siguientes países?',
          bankAccountJurisdictionsOptions: {
            unitedStates: 'EE.UU. (USD)',
            europeanUnion: 'Unión Europea (EUR)',
            noneOfTheAbove: 'Ninguna de las opciones anteriores',
          },
          preferredCurrencies:
            '¿Qué monedas desea utilizar para transacciones de entrada y salida?',
          preferredCurrenciesDescription: 'Por favor, seleccione al menos una.',
          otherCurrencyPlaceholder: 'Por favor, especifique',
        },
      },
      '4': {
        title: 'Detalles del Negocio',
        submitApplication: 'Enviar solicitud',
        form: {
          businessOperatingAddress: 'Dirección operativa del negocio',
          businessCountry: '¿En qué país está ubicado su negocio?',
          countryPlaceholder: 'País',
          statePlaceholder: 'Estado',
          monthlyTransferVolume: 'Volumen de transferencia mensual',
          estimatedTransactionVolume:
            '¿Cuál fue el volumen de transacciones estimado del mes pasado?',
          selectPlaceholder: 'Por favor seleccione',
          monthlyVolume: {
            '0_4999': 'Menos de $4,999',
            '5000_9999': '$5,000 - $9,999',
            '10000_99999': '$10,000 - $99,999',
            '100000_499999': '$100,000 - $499,999',
            '500000_999999': '$500,000 - $999,999',
            '1000000_5000000': '$1,000,000 - $5,000,000',
            '5000000_plus': 'Más de $5,000,000',
          },
          additionalDetails: 'Detalles adicionales',
          additionalDetailsDescription:
            'Describa la naturaleza del negocio y sus objetivos para la cuenta.',
        },
      },
    },
    submitted: {
      title: 'Tu registro ha sido\nenviado con éxito',
      defaultBody:
        'Gracias por tu interés. Nuestro equipo revisará tu información y se pondrá en contacto contigo <0>por correo electrónico</0> si podemos otorgarte acceso a una cuenta.',
      bridgeBody:
        'Gracias por tu interés. Nuestro equipo revisará tu información y se pondrá en contacto contigo <0>por correo electrónico</0> cuando podamos atenderte.',
      conciergeBody:
        '¡Excelente! Alguien de nuestro equipo se pondrá en contacto contigo pronto. Si hay algún retraso, no dudes en contactarnos directamente aquí:',
      returnToWebsite: 'Volver al sitio web',
      contactUs: 'Conectarse con su especialista de onboarding',
    },
    googleSplash: {
      pendingTitle: 'Tu cuenta aún está\npendiente de aprobación',
      noAccountTitle: 'Este Gmail no está asociado\ncon una cuenta de Sphere',
      applicationId: 'Tu ID de Aplicación:',
      pendingDescription:
        'Hemos recibido tu solicitud de Sphere y estamos procesando tu petición. Nos pondremos en contacto contigo pronto con una actualización sobre el estado de tu cuenta lo antes posible.',
      noAccountDescription:
        'Este Gmail no está vinculado a una cuenta de Sphere.\nCompleta una solicitud para comenzar.',
      returnToLogin: 'Volver al inicio de sesión',
      fillOutApplication: 'Completar solicitud',
    },
    loadingAnimation: {
      calculatingRates: 'Calculando las mejores tasas de cambio...',
      securingTransactions: 'Asegurando tus transacciones...',
      optimizingPayments: 'Optimizando pagos globales...',
      connectingCurrencies: 'Conectando monedas...',
      ensuringCompliance: 'Garantizando estándares de cumplimiento...',
      processingData: 'Procesando datos de forma segura...',
      almostThere: 'Ya casi estamos...',
    },
    passwordSetup: {
      loginSuccess: '¡Inicio de sesión exitoso! Bienvenido de nuevo {{email}}',
      newLinkSent: 'Se ha enviado un nuevo enlace a tu correo electrónico.',
      regenerateError:
        'Ocurrió un error al regenerar el token de acceso. Por favor, inténtalo de nuevo.',
      tokenNotFoundOrInvalid: 'Token no encontrado o inválido',
      tokenNotFoundOrInvalidDescription:
        'El token de acceso que estás intentando usar no se encuentra o es inválido. Esto puede deberse a que ha expirado o a un error en el enlace.',
      returnToWebsite: 'Volver al sitio web',
      contactSupport: 'Contactar soporte',
      linkExpired: 'Tu enlace ha expirado',
      linkExpiredDescription:
        'El enlace de correo electrónico ha expirado. Por favor, genera uno nuevo para completar tu registro.',
      redirecting_zero: 'Redirigiendo ahora...',
      redirecting_one: 'Redirigiendo en 1 segundo...',
      redirecting_other: 'Redirigiendo en {{count}} segundos...',
      regenerateLink: 'Regenerar enlace',
      createSignIn: 'Crear Inicio de Sesión',
      createAccount: 'Crear cuenta',
      email: 'Correo electrónico',
      password: 'Contraseña',
      enterPassword: 'Ingresa la Contraseña',
      confirmPassword: 'Confirma la Contraseña',
    },
  },
  validation: {
    required: 'Requerido',
    invalidEmail: 'Por favor, introduce un correo electrónico válido',
    weakPassword:
      'La contraseña debe tener al menos 6 caracteres y contener al menos una letra y un número',
    passwordsDoNotMatch: 'Las contraseñas no coinciden',
  },
  general: {
    comingSoon: 'Próximamente',
    support: 'Soporte',
    optional: 'Opcional',
    back: 'Volver',
    next: 'Siguiente',
    continue: 'Continuar',
    yes: 'Sí',
    no: 'No',
    other: 'Otro',
    selectAllThatApply: 'Selecciona todas las que se aplican',
    unknownError:
      'Ocurrió un error desconocido. Por favor, inténtalo de nuevo más tarde.',
  },
} as const satisfies LocaleResource;

export default es;
